 <template>
  <section class="section-wrapper">

    <div class="plug-wrapper"id="prizes">
      <div class="plug">
        <div class="section-title">
          Условия акции:
        </div>

        <img src="@/assets/snow-left.png" class="snow-decor snow-left desktop" />

        <img src="@/assets/snow-right.png" class="snow-decor snow-right desktop" />

        <img src="@/assets/snow-left@tablet.png" class="snow-decor snow-left tablet" />

        <img src="@/assets/snow-right@tablet.png" class="snow-decor snow-right tablet" />

        <img src="@/assets/snow-left@mobile.png" class="snow-decor snow-left mobile" />

        <img src="@/assets/snow-right@mobile.png" class="snow-decor snow-right mobile" />

        <div class="plug-step">
          <div class="step">1</div>

          <div class="d-flex flex-column justify-content-between">
            <p class="title">
              <a target="_blank" href="https://www.moiglaza.ru/adresa-optik/?utm_source=website&utm_medium=website_url&utm_campaign=promo_winter2024" 
                 onclick="ym(94436170,'reachGoal','Buy_mechanics')"
                 style="text-decoration: underline; color: #223F8A !important;">покупайте*</a> 
              линзы TOTAL30, <br> TOTAL1
              и PRECISION1
            </p>

            <p class="subtext">
              c 1 ноября по 15 декабря 2024 года
            </p>
          </div>
        </div>

        <div class="plug-step">
          <div class="step">2</div>

          <div class="d-flex flex-column justify-content-between">
            <p class="title underline">
              <router-link :to="{ path: '/#mechanics' }"
                           v-scroll-to="'#mechanics'"
                           style="text-decoration: underline; color: #223F8A !important;">регистрируйте
              </router-link> 
              <br> чеки о покупке
            </p>

            <p class="subtext">
              Больше чеков - выше шансы на победу!
            </p>
          </div>
        </div>

        <div class="plug-step">
          <div class="step">3</div>

          <div class="d-flex flex-column justify-content-between">
            <p class="title">
              участвуйте в розыгрыше <br>
              тюбингов (ватрушек)!
            </p>
          </div>
        </div>
      </div>

      <img loading="lazy" class="prizes" src="@/assets/prizes.webp" />
    </div>
  </section>
</template>

<script>

export default {
  name: 'Prizes',

  data: () => ({
  }),

  computed: {
  },
}
</script>

<style scoped lang="scss">
.section-wrapper {
  background-color: $white;
  padding-top: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 50px;
  background-repeat: no-repeat;
  background-image: none;
  background-position: top -100px right;

  @media(max-width: 1050px){
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 60px;
  }

  @media(max-width: 756px){
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }

  @media(max-width: 576px){
    padding-bottom: 30px;
  }
}

.prizes {
  max-width: 494px;
  height: 100%;
  margin-left: 90px;
  display: flex;
  flex-shrink: 0;
  max-height: 494px;

  @media(max-width: 1600px){
    max-width: 420px;
    margin-left: 50px;
  }

  @media(max-width: 1440px){
    max-width: 380px;
    max-height: 380px;
  }

  @media(max-width: 1050px){
    max-width: 340px;
    max-height: 340px;
  }

  @media(max-width: 999px){
    max-width: 310px;
    max-height: 310px;
  }

  @media(max-width: 756px){
    max-width: 260px;
    max-height: 260px;
  }

  @media(max-width: 576px){
    margin-left: 0;
  }
}

.mobile-br {
  @media(max-width: 756px){
    display: block !important;
  }
}

.section-title {
  color: $blue;
  font-size: 40px;
  margin-bottom: 60px;
  white-space: nowrap;

  @media(max-width: 999px){
    font-size: 30px;
  }

  @media(max-width: 576px){
    font-size: 20px;
    margin-bottom: 39px;
    margin-top: 20px;
  }
}

.button {
  max-width: 250px;
  font-size: 18px;
  height: 54px;
  line-height: 18px;
}

.img-text {
  margin-top: 30px;
  font-family: $regular;

  @media(max-width: 768px){
    font-family: $regular;
  }

  p {
    margin-bottom: 0;
    text-transform: uppercase;
    color: $dark-blue;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;

    @media(max-width: 756px){
      font-size: 22px;
    }

    @media(max-width: 768px){
      font-weight: 700;
      font-size: 19px;
      line-height: 116%;
    }

    &:first-child {
      margin-right: 13px;
      font-size: 72px;
      line-height: 50px;

      @media(max-width: 768px){
        font-weight: 32px;
        font-size: 46px;
      }
    }
  }

  span {
    background-color: #8dccf3;
    padding: 0 3px;
  }
}

.plug-wrapper {
  width: 100%;
  margin-top: -370px;
  justify-content: center;
  display: flex;
  max-width: 1550px;
  align-items: center;
  padding: 60px 30px;
  background: #EDF4FF;
  box-shadow: 0px 0px 26.8px rgba(0, 48, 135, 0.2);
  border-radius: 40px;

  @media(max-width: 1600px){
    max-width: 1150px;
  }

  @media(max-width: 1400px){
    max-width: 980px;
  }

  @media(max-width: 1200px){
    max-width: 850px;
    padding-top: 50px 30px;
  }

  @media(max-width: 1050px){
    max-width: none;
    padding-top: 48px;
    margin-top: -130px;
    padding-bottom: 50px;
    justify-content: space-between;
  }

  @media(max-width: 756px){
    padding: 26px 15px;
    margin-top: -120px;
    align-items: center;
    flex-direction: column;
  }

  @media(max-width: 576px){
    padding: 25px 10px;
    margin-top: -20px;
    flex-direction: column-reverse;
  }
}

.plug {
  display: flex;
  color: $white;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  width: fit-content;


  @media(max-width: 760px){
    flex-direction: column;
    padding: 26px 15px;
    align-items: center;
  }

  @media(max-width: 576px){
    padding: 25px 10px;
    align-items: flex-start;
  }

  @media(max-width: 420px){
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .plug-step {
    display: flex;
    margin-bottom: 40px;

    @media(max-width: 756px){
      margin-left: 0;
      margin-right: 0;
      margin-top: 15px;
      margin-bottom: 15px;
      width: 285px;
    }

    @media(max-width: 360px){
      width: 250px;
    }
  }

  .step {
    background-color: $blue;
    border-radius: 50%;
    color: $white;
    font-weight: 700;
    font-size: 20px;
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-right: 15px;
    line-height: 80%;

    @media(max-width: 756px){
      width: 24px;
      height: 24px;
      margin-right: 7px;
      font-size: 14px;
    }
  }

  p {
    margin-bottom: 0;
  }

  .title {
    font-size: 28px;
    line-height: 100%;
    font-weight: 700;
    font-family: $regular;
    text-transform: uppercase;
    margin-bottom: 7px;
    color: $darker-blue;
    line-height: 110%;

    @media(max-width: 1600px){
      font-size: 22px;
    }

    @media(max-width: 1100px){
      font-size: 20px;
    }

    @media(max-width: 756px){
      font-size: 18px;
    }

    @media(max-width: 756px){
      font-size: 16px;
      font-weight: 700;
      line-height: 110%;
      font-family: $regular;
    }
  }

  .subtext {
    line-height: 106%;
    color: $darker-grey;

    @media(max-width: 1100px){
      font-size: 14px;
    }

    @media(max-width: 756px){
      line-height: 100%;
      font-family: $regular;
    }
  }
}

.snow-decor {
  position: absolute;
}

.desktop {
  @media(max-width: 1100px) {
    display: none;
  }
}

.tablet {
  display: none;

  @media(max-width: 1100px){
    display: block;
  }

  @media(max-width: 576px){
    display: none;
  }
}

.mobile {
  display: none !important;

  @media(max-width: 576px){
    display: block !important;
  }
}

.snow-left {
  left: 10px;
  top: -210px;

  @media(max-width: 1100px) {

  }

  @media(max-width: 820px){
    top: -90px;
  }

  @media(max-width: 576px) {
    top: -10px;
  }
}

.snow-right {
  right: 15px;
  top: -230px;

  @media(max-width: 1100px) {

  }

  @media(max-width: 820px){
    top: -110px;
  }

  @media(max-width: 576px) {
    top: -30px;
  }
}
</style>
